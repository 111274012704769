<template>
  <div class="house-master h-100_">
    <div class="room-body h-100_ d-f">
      <clock-warn-header />
      <clock-warn-search-header @search="search"></clock-warn-search-header>
      <div class="content-box">
        <el-table :data="warnTableList" style="width: 100%" :height="height">
          <el-table-column label="序号" type="index" width="100">
          </el-table-column>
          <el-table-column prop="studentName" label="姓名"> </el-table-column>
          <el-table-column prop="orgInfo" label="所在院系"> </el-table-column>
          <el-table-column prop="roomNo" label="房号"> </el-table-column>
          <el-table-column prop="alarmContent" label="告警内容">
          </el-table-column>
        </el-table>
        <common-paginations
          @current-change="(val) => (querySearch.pageNum = val)"
          :current-page="querySearch.pageNum"
          :page-size="querySearch.pageSize"
          @size-change="handleSizeChange"
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="warnTotal"
        ></common-paginations>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import CommonPaginations from "../../components/commonPaginations.vue";
const { mapActions, mapState } = createNamespacedHelpers("setting");
const { mapActions: clockMapAction, mapState: clockMapState } =
  createNamespacedHelpers("clock");
import ClockWarnHeader from "./clockWarnHeader.vue";
import ClockWarnSearchHeader from "./clockWarnSearchHeader.vue";
export default {
  name: "house-master",
  components: {
    ClockWarnHeader,
    CommonPaginations,
    ClockWarnSearchHeader,
  },
  data() {
    return {
      height: 0,
      querySearch: {
        pageNum: 1,
        pageSize: 50,
      },
      cycleList: [
        { id: 0, name: "按月" },
        { id: 1, name: "按周" },
      ],
      days: "",
      cycle: 0,
      valueList: [],
      warnTableList: [],
      params: {},
      warnTotal: 0,
    };
  },
  methods: {
    ...mapActions(["getAllSettingMessage", "updateSettingInfo"]),
    ...clockMapAction(["getWarnTable", "batSetRead"]),
    search(params) {
      this.params = params;
      this.querySearch = {
        pageNum: 1,
        pageSize: 50,
      };
      this.getDataList(params);
    },
    handleSizeChange(pageSize) {
      this.querySearch = {
        pageNum: 1,
        pageSize: 50,
      };
    },
    getDataList(params) {
      const param = {
        ...this.params,
        ...this.querySearch,
        ...params,
      };
      this.getWarnTable(params).then((res) => {
        this.warnTableList = res.list;
        this.warnTotal = res.total;
        const ids = res.list.map((it) => it.id).join(",");
        ids && this.batSetRead(ids);
      });
    },
  },
  mounted() {
    this.getAllSettingMessage();
    this.$nextTick(() => {
      this.height = this.$el.clientHeight - 380;
    });
    this.getDataList();
  },
  computed: {
    ...mapState(["settingBaseinfo"]),
    ...clockMapState([]),
  },
  watch: {
    settingBaseinfo(val) {
      this.valueList = val[0].paramValue.split(";");
      this.days = this.valueList[0];
    },
    cycle(val) {
      this.days = this.valueList[val];
    },
  },
};
</script>

<style lang="scss" scoped>
.house-master {
  background: #f4f5f6;
  padding: 24px;

  .header {
    line-height: 50px;
    font-weight: bold;
  }
  .scroll {
    height: calc(100vh - 250px);
    overflow: auto;
  }
  .room-body {
    flex-direction: column;
    flex: 1;
    margin-left: 24px;
    width: auto;
    border: 1px solid #d7d8d9;
    .content-box {
      padding: 0 24px;
      border-bottom: 1px solid #ddd;
      padding-bottom: 30px;
      background: #fff;
    }
    .set-title {
      margin: 20px 0;
      padding: 0 5px;
    }
  }
}
</style>
