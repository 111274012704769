<template>
  <div>
    <div class="room-body-header d-f ai-c jc-sb">
      <span class="d-f ai-c">
        <common-timer v-model="dates" @change="search"></common-timer>
        <!-- <el-date-picker
          v-model="dates"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          clearable
          @clear="search"
        >
        </el-date-picker> -->
        <!-- <el-select
          v-model="readFlag"
          placeholder="请选择"
          style="margin: 0 8px; width: 100px"
          @change="clockChange"
        >
          <el-option
            v-for="item in readList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select> -->
        <my-input
          placeholder="姓名、房间"
          v-model="complexCondition"
          clearable
          style="width: 200px; margin: 0 8px"
          @clear="search"
        />
        <el-button type="primary" @click="search"> 搜 索</el-button>
        <!-- <el-button type="primary" @click="add"> 新增数据</el-button> -->
      </span>
      <span class="d-f ai-c">
        <common-daochu
          v-if="!isMM"
          title="全部导出"
          @click="exportData(true)"
          type="text"
          class="icon-btn-box"
        >
          <img
            style=""
            class="c-p icon-btn icon-btn-all"
            src="@/assets/daochuquanbu.svg"
            alt=""
            srcset=""
          />
        </common-daochu>
      </span>
      <div></div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions, mapMutations } = createNamespacedHelpers("clock");
const { mapActions: settingAction } = createNamespacedHelpers("setting");
import dayjs from "dayjs";
export default {
  name: "unlock-body-header",
  data() {
    return {
      name: "",
      dates: [
        dayjs().subtract(3, "month").format("YYYY-MM-DD"),
        dayjs().format("YYYY-MM-DD"),
      ],
      times: [
        new Date(dayjs().format("YYYY-MM-DD") + " 00:00:00"),
        new Date(dayjs().format("YYYY-MM-DD") + " 23:59:59"),
      ],
      identifyFlag: "", // 角色
      readFlag: 0,
      complexCondition: "",
      alarmType: "1",
      readList: [
        { id: 0, name: "未读" },
        { id: 1, name: "已读" },
      ],
    };
  },
  methods: {
    ...mapActions([
      "getTableList",
      "batAdd",
      "saveRoomInfo",
      "getUnClockNumber",
      "getUnclockExcel",
      "getClockExcel",
      "addWarnData",
      "getAllWarnInfoExcel",
    ]),
    ...mapMutations(["setDateRange", "setSectionItem"]),
    ...settingAction(["getCalanderInfo"]),
    add() {
      // this.addWarnData();
    },
    search(params) {
      this.$emit("search", {
        // readFlag: this.readFlag,
        complexCondition: this.complexCondition,
        alarmEndDate: this.dates?.[1] ?? "",
        alarmStartDate: this.dates?.[0] ?? "",
        pageNum: 1,
        pageSize: 50,
      });
    },
    sure() {
      // 批量添加确认
      const { build, cell, room } = this.batchAdd;
      if (build && cell && room) {
        this.batchAdd(this.batchAdd);
      }
    },
    clockChange() {
      this.search();
    },
    addRoomFunc(data) {
      this.saveRoomInfo(data).then(() => {
        this.drawer = false;
      });
    },
    goToWarn() {
      this.$router.push("/clock/warnSet");
    },
    goToAnalysis() {
      this.$router.push("/clock/analysis");
    },
    getUnclockFunc() {
      this.setDateRange(this.dates);
      this.getUnClockNumber({
        startTime: this.dates[0] || "",
        endTime: this.dates[1] || "",
      });
    },
    exportData(all = false) {
      this.getAllWarnInfoExcel();
    },
  },
  mounted() {
    this.search();
    this.getCalanderInfo(new Date().getFullYear()).then((alls) => {
      this.times = alls.reduce(
        (c, n) =>
          c.length || !n.clockSetting
            ? c
            : n.clockSetting
                .split(";")[1]
                .split("-")
                .map((it) => new Date("2021-01-01 " + it)),
        []
      );
    });
  },
  watch: {
    visible() {
      this.batchAdd = {
        build: "", // 楼栋
        cell: "", //  楼层数量
        room: "", // 房间
      };
    },
  },
  computed: {
    ...mapState(["sectionItem", "unclockNumber"]),
    isMM() {
      return this.$store.state.login.isMM;
    },
  },
};
</script>

<style lang="scss" scoped>
.room-body-header {
  height: 60px;
  background: #fff;
  padding: 0 24px;
}
.room-header-add {
  .el-input {
    margin-bottom: 12px;
    width: 100%;
  }
  button {
    margin: 0 4px;
    width: 88px;
  }
}
</style>